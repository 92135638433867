import React from "react";
import styled from "styled-components";
import DANYW from "../../media/DANYW.webp"; 

const CoverContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fix;
  text-align: center;
  color: white;
  background: url(${DANYW}) center/cover no-repeat; /* Imagen de fondo */
  @media screen and (max-width: 760px) {
    height: 60vh;
  }
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 130px;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);

  @media screen and (max-width: 760px) {
    font-size: 1.8rem;
  }
`;

const Subtitle = styled.h2`
  font-size: 1.4rem;
  font-weight: normal;
  margin-bottom: 30px;
  color: #000;

  @media screen and (max-width: 760px) {
    font-size: 1.2rem;
  }
`;

const Price = styled.h3`
  font-size: 1.5rem;
  font-weight: bold;
  color: rgb(22, 245, 15);
  margin-bottom: 20px;

  @media screen and (max-width: 760px) {
    font-size: 1rem;
    margin-bottom: 35px;
  }
`;

const Description = styled.p`
  font-size: 1.2rem;
  max-width: 800px;
  padding: 0 20px;
  color: #000;

  @media screen and (max-width: 760px) {
    font-size: 0.9rem;
    display: none;
  }
`;

const Cover = () => {
  return (
    <CoverContainer>
      <Title>DISEÑO Y DESARROLLO DE PÁGINAS WEB</Title>
      <Subtitle></Subtitle>
      <Price>Desde $140.000 (POR ÚNICA VEZ)</Price>
      <Description>
        Web Simple | Landing Page | Profesional | E-commerce | Presencia en Internet para Empresas y Negocios
      </Description>
    </CoverContainer>
  );
};

export default Cover;
/*CONFI PARA VIDEO 
const VideoBackground = styled.video
  position: absolute;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  z-index: -1;

  @media screen and (max-width: 760px) {
    height: 60vh;
  }
;*/