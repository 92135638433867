import React, { useState } from 'react';
import styled from 'styled-components';
import { supabase } from '../../supabaseClient';

const FormContainer = styled.div`
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const FormTitle = styled.h2`
  text-align: center;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const FormInput = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const FormButton = styled.button`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

function CrearServicio() {
  const [service, setServicio] = useState({
    name: '',
    description: '',
    precio_unico: '',
    precio_dos_pagos: '',
  });

  const [imageFile, setImageFile] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setServicio({ ...service, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!service.name || !service.description || !service.precio_unico || !imageFile) {
      alert('Todos los campos son obligatorios');
      return;
    }

    try {
      const fileName = `${Date.now()}_${imageFile.name}`;
      const { data: imageData, error: imageError } = await supabase
        .storage
        .from('servicios-imagenes')
        .upload(`images/${fileName}`, imageFile);

      if (imageError) {
        console.error('Error al subir la imagen:', imageError.message);
        alert('Error al subir la imagen');
        return;
      }

      const { data } = supabase
        .storage
        .from('servicios-imagenes')
        .getPublicUrl(`images/${fileName}`);

      const imageUrl = data.publicUrl;

      if (!imageUrl) {
        alert('No se pudo obtener la URL de la imagen');
        return;
      }

      const { error } = await supabase
        .from('servicios')
        .insert([{
          name: service.name,
          description: service.description,
          precio_unico: service.precio_unico,
          precio_dos_pagos: service.precio_dos_pagos || null,
          imagen: imageUrl,
        }]);

      if (error) {
        console.error('Error al crear el servicio:', error.message);
        alert('Error al crear el servicio');
      } else {
        alert('Servicio creado exitosamente');
        setServicio({
          name: '',
          description: '',
          precio_unico: '',
          precio_dos_pagos: '',
        });
        setImageFile(null);
      }
    } catch (err) {
      console.error('Error:', err);
    }
  };

  return (
    <FormContainer>
      <FormTitle>Crear Servicio</FormTitle>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <FormInput
            type="text"
            name="name"
            placeholder="Nombre del servicio"
            value={service.name}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <FormInput
            type="text"
            name="description"
            placeholder="Descripción"
            value={service.description}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <FormInput
            type="number"
            name="precio_unico"
            placeholder="Precio"
            value={service.precio_unico}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <FormInput
            type="number"
            name="precio_dos_pagos"
            placeholder="Precio en dos pagos (opcional)"
            value={service.precio_dos_pagos}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <FormInput
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
        </FormGroup>
        <FormButton type="submit">Crear Servicio</FormButton>
      </form>
    </FormContainer>
  );
}

export default CrearServicio;
